// src/components/ResourcesList.js

import React from 'react'
import ResourceCard from './ResourceCard.js'

function ResourcesList(props) {
  return (
    <ul className="scroll-trigger grid gap-responsive-xl-32 md:grid-cols-2 lg:grid-cols-3">
      {props.resources.map((node, index) => {
        return (
          <li key={index}>
            <ResourceCard
              featured={false}
              heroImage={node.node[node.node.contentTypeName].heroImage}
              thumbnailColor={
                node.node[node.node.contentTypeName].thumbnailColor
              }
              type={node.node.contentTypeName}
              title={node.node.title}
              description={node.node[node.node.contentTypeName].description}
              content={node.node.content}
              uri={node.node.uri}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default ResourcesList
