// src/templates/pages/ResourcesListing.js

import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../../components/Layout.js'
import ResourcesHeader from '../../components/ResourcesHeader.js'
import ResourcesFilter from '../../components/ResourcesFilter.js'
import TagsFilter from '../../components/TagsFilter.js'
import ResourceCard from '../../components/ResourceCard.js'
import ResourcesList from '../../components/ResourcesList.js'
import Pagination from '../../components/Pagination.js'

function ResourcesListing(props) {
  const pageData = props.data.wpPage.template.resourcesPage
  const resourcesData = props.data.allWpContentNode
  const tagsList = []
  props.pageContext.tagsList.edges.map((node, index) => {
    tagsList.push({
      name: node.node.name,
      slug: node.node.slug,
    })
  })

  return (
    <Layout>
      <Seo post={props.data.wpPage} />

      <ResourcesHeader
        title={props.data.wpPage.title}
        description={pageData.pageDescription}
      />

      <div className="mx-auto max-w-[97rem] px-responsive-xl-32 lg:px-responsive-xl-64">
        <div className="grid gap-responsive-xl-128 md:grid-cols-sidebar md:gap-responsive-xl-64">
          <div className="sidebar flex flex-wrap items-center md:ml-0 md:block md:border-r md:border-granite-200 md:pl-responsive-xl-24 md:pr-responsive-xl-32 md:text-left">
            <span className="mr-responsive-xl-4 mb-responsive-xl-16 font-bold text-meta-md uppercase leading-relaxed tracking-widest text-granite-600 md:hidden">
              Filter by:
            </span>

            <ResourcesFilter
              section={'all'}
              resourceFilters={props.pageContext.resourceFilters}
            />

            <hr className="my-responsive-xl-32 hidden border-granite-200 md:block" />

            <TagsFilter
              postType={''}
              tagsList={tagsList}
              currentTag={''}
              partiallyActive={true}
            />
          </div>
          <div className="results space-y-responsive-xl-32">
            {props.pageContext.currentPage === 1 && (
              <>
                <section className="scroll-trigger pb-responsive-xl-32 md:border-b md:border-granite-200">
                  <ResourceCard
                    featured={true}
                    heroImage={
                      pageData.featuredResource[
                        pageData.featuredResource.contentTypeName
                      ].heroImage
                    }
                    thumbnailColor={
                      pageData.featuredResource[
                        pageData.featuredResource.contentTypeName
                      ].thumbnailColor
                    }
                    type={pageData.featuredResource.contentTypeName}
                    title={pageData.featuredResource.title}
                    description={
                      pageData.featuredResource[
                        pageData.featuredResource.contentTypeName
                      ].description
                    }
                    content={pageData.featuredResource.content}
                    uri={pageData.featuredResource.uri}
                  />
                </section>
              </>
            )}

            <ResourcesList resources={resourcesData.edges} />

            <Pagination
              currentPage={props.pageContext.currentPage}
              numPages={props.pageContext.numPages}
              pageSlug="resources"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ResourcesListing

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    wpPage(template: { templateName: { eq: "Resources Listing" } }) {
      seo {
        ...SeoFragment
      }
      title
      template {
        ... on WpTemplate_ResourcesListing {
          resourcesPage {
            pageDescription
            featuredResource {
              ... on WpArticle {
                title
                uri
                contentTypeName
                content
                article {
                  description
                  thumbnailColor
                  heroImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          height: 900
                          width: 1350
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
              ... on WpPodcast {
                title
                uri
                contentTypeName
                content
                podcast {
                  description
                  thumbnailColor
                  heroImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          height: 900
                          width: 1350
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
              ... on WpReport {
                title
                uri
                contentTypeName
                content
                report {
                  description
                  thumbnailColor
                  heroImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          height: 900
                          width: 1350
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
              ... on WpWebinar {
                title
                uri
                contentTypeName
                content
                webinar {
                  description
                  thumbnailColor
                  heroImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          height: 900
                          width: 1350
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpContentNode(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        contentTypeName: { in: ["article", "podcast", "report", "webinar"] }
      }
    ) {
      edges {
        node {
          ... on WpArticle {
            title
            uri
            contentTypeName
            content
            article {
              description
              thumbnailColor
              heroImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      height: 230
                      width: 350
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
          }
          ... on WpPodcast {
            title
            uri
            contentTypeName
            content
            podcast {
              description
              thumbnailColor
              heroImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      height: 230
                      width: 350
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
          }
          ... on WpReport {
            title
            uri
            contentTypeName
            content
            report {
              description
              thumbnailColor
              heroImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      height: 230
                      width: 350
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
          }
          ... on WpWebinar {
            title
            uri
            contentTypeName
            content
            webinar {
              description
              thumbnailColor
              heroImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      height: 230
                      width: 350
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
