// src/components/Pagination.js

import React from 'react'
import { Link } from 'gatsby'

function Pagination(props) {
  const isFirst = props.currentPage === 1
  const isLast = props.currentPage === props.numPages
  const prevPage =
    props.currentPage - 1 === 1
      ? ''
      : (props.currentPage - 1).toString() + '/'
  const nextPage = (props.currentPage + 1).toString() + '/'
  const numPages = props.numPages
  const pageSlug = props.pageSlug

  return (
    <>
      {numPages > 1 && (
        <ul className="flex flex-wrap items-center justify-center space-x-responsive-xl-8">
          {!isFirst && (
            <li key="pagination-prev">
              <Link
                to={`/${pageSlug}/${prevPage}`}
                rel="prev"
                className="no-underline"
              >
                ← Previous
              </Link>
            </li>
          )}

          {Array.from({ length: numPages }, (_, i) => (
            <li key={`pagination-number${i + 1}`}>
              <Link
                className="flex items-center justify-center border-b-2 border-transparent p-responsive-xl-8 no-underline"
                activeClassName={'border-fuscia-full'}
                to={`/${pageSlug}/${i === 0 ? '' : i + 1 + '/'}`}
              >
                {i + 1}
              </Link>
            </li>
          ))}

          {!isLast && (
            <li key="pagination-next">
              <Link
                to={`/${pageSlug}/${nextPage}`}
                rel="next"
                className="no-underline"
              >
                Next →
              </Link>
            </li>
          )}
        </ul>
      )}
    </>
  )
}

export default Pagination
