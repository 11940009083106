// src/components/ResourcesFilter.js

// Very similar to PageTitle, with the exception of usage on the
// ResourcesListing and subsequent child pages. Includes a bottom border
// Possible candidate for refactoring with the PageTitle

import React from 'react'
import { Link, navigate } from 'gatsby'

function ResourcesFilter(props) {
  const filterChange = function (event) {
    const pageHref =
      event.target.value === 'all'
        ? `/resources/`
        : `/resources/${event.target.value}/`
    navigate(pageHref)
  }

  return (
    <>
      {/* small screen controls */}
      <span className="md:hidden mr-responsive-xl-12">
        <label htmlFor="resource-filters" className="sr-only">
          Filter by resource
        </label>
        <select
          className="styled-select "
          id="resource-filters"
          onChange={filterChange}
          value={props.section}
        >
          {Object.entries(props.resourceFilters).map(
            ([key, value], index) => {
              return (
                <option value={key} key={index}>
                  {value}
                </option>
              )
            }
          )}
        </select>
      </span>

      {/* large screen controls */}
      <ul className="hidden space-y-responsive-xl-12 md:block">
        {Object.entries(props.resourceFilters).map(([key, value], index) => {
          const linkTo = key === 'all' ? `/resources/` : `/resources/${key}/`
          const isPartiallyActive = key === props.section ? true : false

          return (
            <li key={index}>
              <Link
                to={linkTo}
                className="relative text-sm leading-normal no-underline hover:underline"
                activeClassName={
                  'font-bold before:absolute before:-left-[1.25em] before:top-[0.25em] before:block before:h-[0.75em] before:w-[0.75em] before:rounded-full before:bg-fuscia-full before:content-[""]'
                }
                partiallyActive={isPartiallyActive}
              >
                {value}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default ResourcesFilter
