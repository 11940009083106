// src/components/TagsFilter.js

import React from 'react'
import { Link, navigate } from 'gatsby'

function TagsFilter(props) {
  const slug = props.postType
    ? `/resources/${props.postType}/`
    : `/resources/`
  const partiallyActive = props.partiallyActive
    ? props.partiallyActive
    : false

  const filterChange = function(event) {
    const pageHref = event.target.value ? `${slug}tag/${event.target.value}/` : slug
    navigate(pageHref)
  }

  return (
    <>
      {/* small screen controls */}
      <span className="md:hidden">
        <label htmlFor="tag-filters" className="sr-only">Filter by tag</label>
        <select
          className="styled-select"
          id="tag-filters"
          onChange={filterChange}
          value={props.currentTag}>
          <option value="">
            All Topics
          </option>
          {props.tagsList.map((node, index) => {
            return (
              <option
                value={node.slug}
                key={index}>
                {node.name}
              </option>
            )
          })}
        </select>
      </span>

      {/* large screen controls */}
      <ul className="space-y-responsive-xl-12 hidden md:block">
        <li>
          <Link
            to={slug}
            className="relative text-sm leading-normal no-underline hover:underline"
            activeClassName={
              'font-bold before:absolute before:-left-[1.25em] before:top-[0.25em] before:block before:h-[0.75em] before:w-[0.75em] before:rounded-full before:bg-fuscia-full before:content-[""]'
            }
            partiallyActive={partiallyActive}
          >
            All Topics
          </Link>
        </li>
        {props.tagsList.map((node, index) => {
          return (
            <li key={index}>
              <Link
                to={`${slug}tag/${node.slug}/`}
                className="relative text-sm leading-normal no-underline hover:underline"
                activeClassName={
                  'font-bold before:absolute before:-left-[1.5em] before:top-[0.25em] before:block before:h-[0.75em] before:w-[0.75em] before:rounded-full before:bg-fuscia-full before:content-[""]'
                }
                partiallyActive={true}
              >
                {node.name}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default TagsFilter
